import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["saveButton"];

  start () {
    this.saveButtonTarget.classList.add('active');
    this.saveButtonTarget.disabled = true;
  }

  stop () {
    this.saveButtonTarget.classList.remove('active');
    this.saveButtonTarget.disabled = false;
  }
}
