import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["breed", "breedClass", "division", "divisionClass"];

  connect() {
    // var target = this.breedClassTarget;
  }

  handleBreedChange(e) {
    let selectedValue = e.target.value;
    var target = this.breedClassTarget;
    target.innerHTML = '';

    fetch('/option_types/values/' + selectedValue)
    .then(res => res.json())
      .then(res => {
        res.forEach(val => {
          const opt = document.createElement("option");
          opt.innerText = val.name;
          opt.value = val.id;
          target.appendChild(opt);
        });
    });
  }

  handleDivisionChange(e) {
    let selectedValue = e.target.value;
    var target = this.divisionClassTarget;
    target.innerHTML = '';

    fetch('/option_values/division_classes/' + selectedValue)
    .then(res => res.json())
      .then(res => {
        res.forEach(val => {
          const opt = document.createElement("option");
          opt.innerText = val.name;
          opt.value = val.id;
          target.appendChild(opt);
        });
    });
  }
}
