import { Controller } from 'stimulus';

export default class extends Controller {
  handleOptionChange(e) {
    let disable = e.target.dataset.disable == 'true';
    Array.from(e.target.form.submit).forEach(
      (formElement) => (formElement.disabled = disable ? true : false)
    );
    let target = document.getElementById(e.target.dataset.target);
    if (e.target.dataset.message) {
      target.innerHTML = e.target.dataset.message;
    } else {
      target.innerHTML = '';
    }
  }
}
