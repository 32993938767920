import { Controller } from "stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = ["exhibitorSignature", "parentSignature", "exhibitorSignatureUploader", "parentSignatureUploader", "saveButton", "certificationType"];

  connect() {
    var exhibitorSignature = this.exhibitorSignatureTarget;
    var saveButton = this.saveButtonTarget;
    var certificationType = this.certificationTypeTarget;

    this.signaturePadExhibitor = new SignaturePad(exhibitorSignature, {
      backgroundColor: 'rgb(255, 255, 255)'
    });

    if (certificationType.value === 'junior') {
      var parentSignature = this.parentSignatureTarget;
      this.signaturePadParent = new SignaturePad(parentSignature, {
        backgroundColor: 'rgb(255, 255, 255)'
      });
    }

    saveButton.disabled = true;
  }

  clearExhibitorSignature() {
    this.signaturePadExhibitor.clear();
    this.saveButtonTarget.disabled = true;
    this.exhibitorSignatureUploaderTarget.value = null;
  }

  clearParentSignature() {
    this.signaturePadParent.clear();
    this.saveButtonTarget.disabled = true;
    this.parentSignatureUploaderTarget.value = null;
  }

  saveExhibitorSignature() {
    var exhibitorUploader = this.exhibitorSignatureUploaderTarget;
    exhibitorUploader.value = this.signaturePadExhibitor.toDataURL('image/jpeg');
    this.checkCanSave();
  }

  saveParentSignature() {
    var parentUploader = this.parentSignatureUploaderTarget;
    parentUploader.value = this.signaturePadParent.toDataURL('image/jpeg');
    this.checkCanSave();
  }

  checkCanSave() {
    var certificationType = this.certificationTypeTarget;

    if (certificationType.value === 'junior') {
      if (!this.signaturePadExhibitor.isEmpty() &&
          !this.signaturePadParent.isEmpty() &&
          this.exhibitorSignatureUploaderTarget.value != "" &&
          this.parentSignatureUploaderTarget.value != "" ) {
        this.saveButtonTarget.disabled = false;
      }
    } else {
      if (!this.signaturePadExhibitor.isEmpty() &&
          this.exhibitorSignatureUploaderTarget.value != "") {
        this.saveButtonTarget.disabled = false;
      }
    }
  }

}
