import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["stallNumber"];

  connect() {
    console.log("trailer controller connected");
  }

  checkStallNumber() {
    // let selectedValue = this.stallNumberTarget.value;
    // fetch('/trailers/check_stall_number/' + selectedValue)
    // .then(res => res.json())
    //   .then(res => {
    //     if (res.status == "error") {
    //       alert('Stall number is already taken');
    //     }
    // });
  }

}
