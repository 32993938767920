import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["passId", "addLink"];

  connect() {
    console.log("pass controller connected");
  }

  handleQTYChange(e) {
    
    console.log("qty changed to " + e.target.value);

    // var passNumber = this.passIdTarget;
    var linkBtn = this.addLinkTarget;

    if (!e.target.checkValidity()) {
      linkBtn.classList.add("disabled");
    } else {
      linkBtn.classList.remove("disabled");
    }

    // var paramsString = linkBtn.href.split('?')[1];
    // var params = new URLSearchParams(paramsString);
    // console.log(params);

    // var qtyparam = params.get('quantity');

    // if (qtyparam == null) {
    //   params.set('quantity', e.target.value)
    // }

    // console.log(params.get('quantity'))

    var url = new URL(linkBtn.href);
    var search_params = url.searchParams;

    search_params.set('quantity', e.target.value);
    url.search = search_params.toString();

    var new_url = url.toString();
    // console.log(new_url);

    linkBtn.href = new_url;

    // var oldurl = linkBtn.href;
    // var newurl = oldurl + '&quantity=' + e.target.value;

    // console.log('pass id ' + passNumber.value);
    // console.log('link url ' + linkBtn.href);
    // console.log('new url ' + newurl);

    // console.log('updating...');
    // linkBtn.href = newurl;

    // console.log('link url ' + linkBtn.href);
  }

}