import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
  }


  search() {
    this.element.action = this.searchUrl
    this.element.submit()
  }

  exportCsv() {
    this.element.action = this.exportCsvUrl
    this.element.submit()
  }

  get searchUrl(){
    return this.data.get('searchUrl')
  }

  get exportCsvUrl(){
    return this.data.get('exportCsvUrl')
  }
}
