import { Controller } from "stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = ["agentSignature", "saveButton", "agentSignatureUploader"];

  connect() {
    if (this.hasAgentSignatureTarget) {
      const agentSignature = this.agentSignatureTarget;

      this.signaturePadAgent = new SignaturePad(agentSignature, {
        backgroundColor: "rgb(255, 255, 255)",
      });
    }

    if (this.hasSaveButtonTarget) {
      const saveButton = this.saveButtonTarget;
      saveButton.disabled = true;
    }
  }

  clearAgentSignature() {
    this.signaturePadAgent.clear();
    this.saveButtonTarget.disabled = true;
    this.agentSignatureUploaderTarget.value = null;
  }

  saveAgentSignature() {
    const agentUploader = this.agentSignatureUploaderTarget;
    agentUploader.value = this.signaturePadAgent.toDataURL("image/jpeg");
    this.checkCanSave();
  }

  checkCanSave() {
    if (
      !this.signaturePadAgent.isEmpty() &&
      this.agentSignatureUploaderTarget.value != null
    ) {
      this.saveButtonTarget.disabled = false;
    }
  }
}
