import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["lifeMember"];

  handleMembershipType(e) {
    if (e.currentTarget.value === "volunteer") {
      this.lifeMemberTarget.value = "";
      this.lifeMemberTarget.disabled = true;
    } else {
      this.lifeMemberTarget.disabled = false;
    }
  }
}
