import { Controller } from "stimulus";
import SlimSelect from 'slim-select';
import 'slim-select/dist/slimselect.css';

export default class extends Controller {
  static targets = ["optionType", "addLink"];

  connect() {
    
    if (this.hasOptionTypeTarget) {
      this.optionTypeSelect = new SlimSelect({
        select: this.optionTypeTarget
      });
    }
  }

  handleQTYChange(e) {
    
    var linkBtn = this.addLinkTarget;

    if (!e.target.checkValidity()) {
      linkBtn.classList.add("disabled");
    } else {
      linkBtn.classList.remove("disabled");
    }

    var url = new URL(linkBtn.href);
    var search_params = url.searchParams;

    search_params.set('quantity', e.target.value);
    url.search = search_params.toString();

    var new_url = url.toString();

    linkBtn.href = new_url;
  }
}
