import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option1Select", "option2Select", "option3Select"];

  connect() {
    console.log("l trailer controller connected");
  }

  handleOpenCattleChange(e) {
    let openCattle = e.target.value;

    var target1 = this.option1SelectTarget;
    var target2 = this.option2SelectTarget;
    var target3 = this.option3SelectTarget;
    
    target1.innerHTML = '';
    target2.innerHTML = '';
    target3.innerHTML = '';

    var options = [];

    if (openCattle == 'true') {
        options = ['Open - Brahman', 'Open - Brangus/Red Brangus', 'Open - Beefmaster', 'Open - Miniature Hereford', 'Open - Simbrah/Percentage Simbrah', 'Open - Texas Longhorn'].sort();
    } else {
        options = ['Market Steer Wave 1', 'Market Steer Wave 2', 'Open Beef Cattle', 'Purebred Gilts', 'Poultry', 'Registered Boer Goat', 'Registered Breeding Sheep-Rams', 'Angora Goats - Does', 'Angora Goats - Bucks', 'Commercial Doe', 'Commercial Ewe', 'Commercial Steers', 'Crossbred Gilts', 'Dairy Cattle', 'Junior Beef Heifers Wave 1', 'Junior Beef Heifers Wave 2', 'Market Barrow Wave 1', 'Market Barrow Wave 2', 'Market Goat', 'Market Lamb', 'Tack', 'Rose Classic / All Breeds', 'Registered Breeding Sheep-Ewes'].sort();
    }

    const opt1 = document.createElement("option");
    opt1.innerText = "Select Option 1";
    opt1.value = "";

    const opt2 = document.createElement("option");
    opt2.innerText = "Select Option 2";
    opt2.value = "";

    const opt3 = document.createElement("option");
    opt3.innerText = "Select Option 3";
    opt3.value = "";

    target1.appendChild(opt1);
    target2.appendChild(opt2);
    target3.appendChild(opt3);

    options.forEach(val => {
        console.log(val);
        const opt1 = document.createElement("option");
        opt1.innerText = val;
        opt1.value = val;

        const opt2 = document.createElement("option");
        opt2.innerText = val;
        opt2.value = val;

        const opt3 = document.createElement("option");
        opt3.innerText = val;
        opt3.value = val;
        
        target1.appendChild(opt1);
        target2.appendChild(opt2);
        target3.appendChild(opt3);
    });
  }

}