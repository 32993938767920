import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element);
    this.modal.show();

    this.showFunction = function(e) {
      const addressType = document.getElementById("member_address_address_type");

      if (addressType) {
        addressType.focus();
      }

      const phoneType = document.getElementById("member_phone_phone_type");

      if (phoneType) {
        phoneType.focus();
      }

      const email = document.getElementById("member_email_email");

      if (email) {
        email.focus();
      }

      const membership = document.getElementById("membership_membership_type");

      if (membership) {
        membership.focus();
      }

      const committee = document.getElementById("member_committee_committee_id");

      if (committee) {
        committee.focus();
      }

      const sub_committee = document.getElementById("member_sub_committee_sub_committee_id");

      if (sub_committee) {
        sub_committee.focus();
      }
    }
    this.hideFunction = function(e) {
      e.currentTarget.remove();
    }

    this.element.addEventListener("shown.bs.modal", this.showFunction);
    this.element.addEventListener("hidden.bs.modal", this.hideFunction);
  }

  disconnect() {
    this.element.removeEventListener("hidden.bs.modal", this.hideFunction);
    this.element.removeEventListener("shown.bs.modal", this.showFunction);
    document.querySelector("turbo-frame[id='modal']").src = null;
  }

  handleSubmitEnd(e) {
    if (e.detail.success) {
      this.modal.hide();
    }
  }
}
