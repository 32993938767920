import { Controller } from "stimulus";
import Inputmask from "inputmask";
import axios from "axios";

export default class extends Controller {
  static targets = [
    "title",
    "deceasedDate",
    "phoneNumber",
    "emergencyPhoneNumber",
    "zipCode",
  ];

  connect() {
    const phoneMask = new Inputmask("(999) 999-9999");

    if (this.hasPhoneNumberTarget) {
      phoneMask.mask(this.phoneNumberTarget);
    }

    if (this.hasEmergencyPhoneNumberTarget) {
      phoneMask.mask(this.emergencyPhoneNumberTarget);
    }

    if (this.hasZipCodeTarget) {
      this.zipCodeBlurListener = this.zipCodeTarget.addEventListener(
        "blur",
        function (e) {
          const zipCode = e.currentTarget.value;

          if (zipCode.length > 0) {
            const url = e.currentTarget.dataset.searchUrl;

            axios.get(`${url}?zip_code=${zipCode}`).then((response) => {
              const { city, state } = response.data;
              const cityEl =
                document.getElementById("member_address_city") ||
                document.getElementById(
                  "member_member_addresses_attributes_0_city"
                );
              const stateEl =
                document.getElementById("member_address_state_id") ||
                document.getElementById(
                  "member_member_addresses_attributes_0_state_id"
                );

              cityEl.value = city;
              stateEl.value = state;
            });
          }
        }
      );
    }

    if (this.hasTitleTarget) {
      this.titleTarget.focus();
    }
  }

  disconnect() {
    if (this.hasPhoneNumberTarget) {
      Inputmask.remove(this.phoneNumberTarget);
    }

    if (this.hasZipCodeTarget) {
      this.zipCodeTarget.removeEventListener("blur", this.zipCodeBlurListener);
    }
  }

  setDeceased(e) {
    this.deceasedDateTarget.disabled = e.currentTarget.value === "false";
  }
}
