import { Controller } from "stimulus";
import SlimSelect from "slim-select";
import "slim-select/dist/slimselect.css";

export default class extends Controller {
  static targets = ["clubs"];

  connect() {
    window.scrollTo(0, 0);
    if (this.hasClubsTarget) {
      this.clubsSelect = new SlimSelect({
        select: this.clubsTarget,
      });
    }
  }
}
